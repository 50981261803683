/* -- FONTS --------------------------------------------------------------------------------------------------------------------------------------------------- */

@font-face {
    font-family: 'TradeGothicLTPro-Bd2';
    src: url('../fonts/TradeGothicLTPro-Bd2.eot?') format('eot'), 
         url('../fonts/TradeGothicLTPro-Bd2.otf')  format('opentype'),
         url('../fonts/TradeGothicLTPro-Bd2.woff') format('woff'), 
         url('../fonts/TradeGothicLTPro-Bd2.ttf')  format('truetype'),
         url('../fonts/TradeGothicLTPro-Bd2.svg#TradeGothicLTPro-Bd2') format('svg');
}

@font-face {
    font-family: 'TradeGothicLTPro-Cn18';
    src: url('../fonts/TradeGothicLTPro-Cn18.eot?') format('eot'), 
         url('../fonts/TradeGothicLTPro-Cn18.otf')  format('opentype'),
         url('../fonts/TradeGothicLTPro-Cn18.woff') format('woff'), 
         url('../fonts/TradeGothicLTPro-Cn18.ttf')  format('truetype'),
         url('../fonts/TradeGothicLTPro-Cn18.svg#TradeGothicLTPro-Cn18') format('svg');
}

.tradegothic {
    font-family: "TradeGothicLTPro-Cn18", Arial, Helvetica, sans-serif;
    font-size: 1em;
    font-weight: normal;
    text-transform: uppercase;
}

.tradegothic-bold, .tradegothic b, .tradegothic strong {
    font-family: "TradeGothicLTPro-Bd2", Arial, Helvetica, sans-serif;
    font-size: 1em;
    font-weight: normal;
    text-transform: uppercase;
}

/* -- TYPOGRAPHY AND CO. -------------------------------------------------------------------------------------------------------------------------------------- */

body {
    background: #f2f2f2;
    color: #333333;
    font-family: Georgia, Times, serif;
    font-size: 20px;
    line-height: 1.25em;
}

h1 {
    color: #333333;
    margin: 0;
    font-family: "TradeGothicLTPro-Bd2", Arial, Helvetica, sans-serif;
    font-size: 2em;
    font-weight: normal;
    line-height: 1.25em;
    text-transform: uppercase;
}

h2 {
    color: #333333;
    margin: 0;
    font-family: "TradeGothicLTPro-Bd2", Arial, Helvetica, sans-serif;
    font-size: 1em;
    font-weight: normal;
    line-height: 1.25em;
    text-transform: uppercase;
}

h3 {
    color: #333333;
    margin: 0;
    font-family: "TradeGothicLTPro-Cn18", Arial, Helvetica, sans-serif;
    font-size: 1em;
    font-weight: normal;
    line-height: 1.25em;
    text-transform: uppercase;
}

h4 {
    color: #808080;
    margin: 0;
    font-family: "TradeGothicLTPro-Cn18", Arial, Helvetica, sans-serif;
    font-size: 1em;
    font-weight: normal;
    line-height: 1.25em;
    text-transform: uppercase;
}

p {
    margin: 0 0 1.5em;
    line-height: 1.5em;
}

a {
    color: #00c0f3;
    font-weight: normal;
    text-decoration: none;
    -webkit-transition: 300ms ease-out color;
    -moz-transition: 300ms ease-out color;
    -o-transition: 300ms ease-out color;
    transition: 300ms ease-out color;
}
a:visited {
    text-decoration: none;
}
a:hover, a:active, a:focus, a.selected, a.active {
    color: #000000;
    outline: none;
    text-decoration: none;
}

a.negative {
    color: #333333;
}
a.negative:hover, a.negative:active, a.negative:focus, a.negative.selected, a.negative.active {
    color: #00c0f3;
}

hr {
    border: 0;
    border-bottom: 1px solid #00c0f3;
    max-width: 100%;
    width: 8.5em;
    margin: 1em auto;
    display: block;
}

/* -- MENUS AND ICONS ----------------------------------------------------------------------------------------------------------------------------------------- */

ul.menu, li.menu {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul.menu.iconlist {
    text-align: center;
}

ul.menu.iconlist li {
    padding: 0 0.5em 1em;
    display: inline-block !important;
}

.icon {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 1.5em 3em;
    border: 0;
    color: transparent;
    width: 1.5em;
    height: 1.5em;
    margin: 0;
    padding: 0;
    display: inline-block;
    position: relative;
    text-indent: 200%;
    outline: none;
    overflow: hidden;
    white-space: nowrap;
}

.icon.svg {
    background: transparent !important;
}

.icon a {
    width: 1.5em;
    height: 1.5em;
    margin: 0;
    padding: 0;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 55;
}

.icon.svg svg {
    width: 1.5em;
    height: 1.5em;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
}

.icon.svg path {
    fill: #00c0f3;
    transition: 300ms ease-out fill;
}
.icon.svg:hover path {
    fill: #000000;
}

.icon.svg.negative path {
    fill: #333333;
}
.icon.svg.negative:hover path {
    fill: #00c0f3;
}

/* -- DARK LAYOUT --------------------------------------------------------------------------------------------------------------------------------------------- */

.dark {
    background: #333333;
    color: #ffffff;
}

.dark h1, .dark h2, .dark h3, .dark h4 {
    color: #ffffff;
}

.dark a:hover, .dark a:active, .dark a:focus, .dark a.selected, .dark a.active, .dark a.negative {
    color: #ffffff;
}
.dark a.negative:hover, .dark a.negative:active, .dark a.negative:focus, .dark a.negative.selected, .dark a.negative.active {
    color: #00c0f3;
}

.dark .icon.negative {
    background-position: center -1.5em;
}

.dark .icon.svg:hover path, .dark .icon.svg.negative path {
    fill: #ffffff;
}

.dark .icon.svg.negative:hover path {
    fill: #00c0f3;
}

/* -- TABLE LAYOUT -------------------------------------------------------------------------------------------------------------------------------------------- */

.table-layout {
    display: table;
    table-layout: fixed;
}

.table-layout .table-row {
    display: table-row;
}

.table-layout .cell {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
