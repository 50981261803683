/* -- CONTENT ------------------------------------------------------------------------------------------------------------------------------------------------- */

section {
    overflow: hidden;
}

.content {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0.5em #000000;
    height: 25em;
    padding: 2em 0;
    position: relative;
    overflow: hidden;
}

.content-flex {
    display: flex;
    align-items: center;
}

.content .text {
    text-align: center;
}

.content h2, .content h3, .content h4 {
    line-height: 1.5em;
}

.content .iconlist .icon {
    margin-top: 0.25em;
}

.content .icon.icon-close {
    position: absolute;
    top: 2.5em;
    right: 1.5em;
    z-index: 300;
}

/* -- MENU ---------------------------------------------------------------------------------------------------------------------------------------------------- */

#menu .content {
    box-shadow: inset 0 -0.5em #000000;
}

#menu .table-layout {
    height: 100%;
    padding: 1.5em;
}

#menu .cell {
    text-align: left;
}

#menu ul.menu {
    font-size: 1.5em;
    line-height: 1.5em;
}

#menu hr {
    font-size: 0.6666666667em;
    margin: 1em 0;
}

#menu .content .icon.icon-close {
    top: 1.5em;
}

/* -- NAVBAR -------------------------------------------------------------------------------------------------------------------------------------------------- */

#navbar {
    background: #ffffff;
    height: 5em;
    overflow: hidden;
}

#toggle-menu {
    padding-top: 1.25em;
    padding-left: 1.5em;
}

#logo img {
    max-height: 3em;
    margin: 1em auto;
    display: block;
}

#like-button {
    padding-top: 0.875em;
    padding-right: 1.5em;
}

#like-button .fb-like {
    width: 55px;
    height: 65px;
    float: right;
}

/* -- FILTERS ------------------------------------------------------------------------------------------------------------------------------------------------- */

#filters .content {
    background: #ffffff;
    border-top: 1px solid #000000;
    box-shadow: none;
    height: auto;
    padding: 1.25em 0;
}

#filters ul.menu li {
    padding: 0 0.5em 0 0;
    display: inline-block !important;
    line-height: 1.5em;
}

#filters a {
    color: #808080;
}
#filters a:hover, #filters a:active, #filters a:focus {
    color: #000000;
}
#filters a.selected {
    color: #00c0f3;
}

#filters .content hr {
    margin-left: 0;
    margin-right: 0;
}

#filters .content .icon.icon-close {
    top: 1.5em;
}

/* -- PROJECTS ------------------------------------------------------------------------------------------------------------------------------------------------ */

#projects {
    padding: 1.5em 0;
}

.project {
    height: 15.75em;
    margin-bottom: 2em;
}

.project .inner {
    height: 100%;
    position: relative;
    overflow: hidden;
    opacity: 0;
}

.project.no-slide-in .inner {
    opacity: 1;
}

.project.slide-in .inner {
    transform: translate3d(0, 100px, 0);
    animation: slide-in 1s linear forwards;
}

@keyframes slide-in {
    to { opacity: 1; transform: translate3d(0, 0, 0); }
}

.project .thumbnail {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 12.25em;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    transition: 300ms ease-in-out transform;
}

.project:hover .thumbnail {
    transform: scale(1.25);
}

.project .infobar {
    background: #ffffff;
    width: 100%;
    height: 3.5em;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 110;
}

.project .infobar:after {
    border-style: solid;
    border-width: 0 0.5em 0.5em;
    border-color: #ffffff transparent;
    content: "";
    width: 0;
    margin-left: -0.5em;
    display: block;
    position: absolute;
    left: 50%;
    top: -0.5em;
    z-index: 109;
}

.project .badge {
    background: #00c0f3;
    width: 3.5em;
    height: 3.5em;
    margin-right: 0.75em;
    float: left;
    display: inline;
    line-height: 1em;
    text-align: center;
    transition: 300ms ease-in-out background;
}
.project:hover .badge {
    background: #0089cf;
}

.project .badge .icon {
    margin-top: 1em;
}

.project .badge .icon.svg path {
    fill: #ffffff;
}

.project h2 {
    margin-top: 0.5em;
}

/* -- ROTATOR ------------------------------------------------------------------------------------------------------------------------------------------------- */

.rotator-container {
    position: relative;
    z-index: 200;
}

.rotator-window {
    height: 100%;
    position: relative;
    overflow: hidden;
    z-index: 210;
}

.rotator-slider {
    width: 10000%;
    height: 100%;
    position: absolute;
}

.rotator-page {
    width: 1%;
    height: 100%;
    float: left;
    display: inline;
    position: relative;
}

.rotator-control {
    width: 4.5em;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 220;
    text-indent: 100%;
    overflow: hidden;
    outline: 0;
    white-space: nowrap;
}
.rotator-control.rotator-control-right {
    left: auto;
    right: 0;
}

.rotator-control.icon.svg a {
    width: 100%;
    height: 100%;
}

.rotator-control.icon.svg svg {
    margin-top: -0.75em;
    position: absolute;
    left: 1.5em;
    top: 50%;
}
.rotator-control.icon.svg.icon-arrow_right svg {
    left: auto;
    right: 1.5em;
}

.rotator-control.icon.svg.rotator-control-inactive path {
    fill: #808080;
}

.rotator-indicators {
    height: 4em;
    margin: 0;
    padding: 0 0 3em;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 0.5em;
    line-height: 1em;
    list-style: none;
    text-align: center;
    z-index: 220;
}

.rotator-indicators .rotator-control-pager {
    margin: 0;
    padding: 0 0.25em;
    display: inline-block !important;
    line-height: 1em;
}

.rotator-indicators .rotator-control-pager a {
    background: #00c0f3;
    border: 0;
    border-radius: 0.5em;
    width: 1em;
    height: 1em;
    margin: 0;
    padding: 0;
    display: inline-block;
    font-weight: normal;
    line-height: 1em;
    text-decoration: none;
    text-indent: 100%;
    overflow: hidden;
    outline: 0;
    white-space: nowrap;
    transition: 300ms ease-out background;
}
.rotator-indicators .rotator-control-pager a:visited {
    text-decoration: none;
}
.rotator-indicators .rotator-control-pager a:hover, .rotator-indicators .rotator-control-pager a:active, .rotator-indicators .rotator-control-pager a:focus, .rotator-indicators .rotator-control-pager.rotator-control-active a {
    background: #ffffff;
    text-decoration: none;
}

.rotator-indicators .rotator-control-pager a .title {
    display: none;
    visibility: hidden;
}

/* -- BRANDS -------------------------------------------------------------------------------------------------------------------------------------------------- */

#clients .rotator-container {
    height: 11.5em;
    margin: 0.5em 0 0;
}

#clients .rotator-container .container {
    height: 100%;
    padding: 0 2.25em;
}

.brand {
    height: 5em;
    margin-bottom: 1.5em;
}

.brand img {
    max-width: 70%;
    max-height: 70%;
    display: inline;
}

/* -- OVERLAY ------------------------------------------------------------------------------------------------------------------------------------------------- */

.overlay {
    background: rgba(242, 242, 242, 0.95);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transform: scale(0.9);
    transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
    z-index: 1000;
}
.overlay.no-transition {
    visibility: visible;
    opacity: 1;
}

.overlay.overlay-open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);  
    transition: transform 0.4s, opacity 0.4s;
}

.overlay .headline {
    height: 6.5em;
    padding: 1em 1.5em 0;
    text-align: center;
}

.overlay .pages {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 1.5em;
    text-align: center;
}

.overlay .rotator-container {
    width: 100%;
    height: 100%;
    padding: 6.5em 0;
    position: absolute;
    top: 0;
    left: 0;
}

.overlay .rotator-window {
    margin: 0 4.5em;
}

.overlay .rotator-page {
    text-align: center;
    overflow: hidden;
}

.overlay .rotator-page .vertical {
    height: 100%;
    display: inline-block;
    vertical-align: middle;
}

.overlay .rotator-page img {
    max-height: 100%;
    max-width: 100%;
    vertical-align: middle;
}

.overlay .rotator-page iframe {
    border: 0;
    width: 1280px;
    max-width: 100%;
}

.overlay .rotator-control.rotator-control-left, .overlay .rotator-control.rotator-control-right {
    margin: 6.5em 0;
}

.overlay .icon.icon-close {
    position: absolute;
    top: 1.5em;
    right: 1.5em;
    z-index: 1100;
}

/* -- FOOTER -------------------------------------------------------------------------------------------------------------------------------------------------- */

#footer {
    background: #ffffff;
    color: #808080;
    height: 5em;
    line-height: 5em;
    text-align: center;
}
